import React from 'react';

export default function IndexSectionHeadersLightReverse1() {
    return (
        <React.Fragment>
            <>
                <section className="pt-6 bg-gray-50 overflow-hidden"><div className="container mx-auto px-4">
    <div className="mb-6">
      <div className="flex items-center justify-between px-6 py-3.5 bg-white rounded-full">
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto">
              <a href="#">
                <img src="https://static.shuffle.dev/uploads/files/03/03eba743df2df50e83283b462b4e53403afef802/feedwall.svg" alt className="max-w-44" /></a>
            </div>
          </div>
        </div>
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto hidden lg:block">
              <ul className="flex items-center justify-center">
                <li className="mr-9"><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#">Fonctionnalités</a></li>
                <li className="mr-9">
                </li>
                <li className="mr-9">
                </li>
                <li><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#tarifs">Tarification</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="w-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-auto hidden lg:block">
              <div className="flex flex-wrap -m-2">
                <div className="w-full md:w-auto p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full" href="https://app.feedwall.fr">Connexion</a></div>
                <div className="w-full md:w-auto p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="https://app.feedwall.fr">S'inscrire</a></div>
              </div>
            </div>
            <div className="w-auto lg:hidden">
              <a className="inline-block" href="#">
                <svg className="navbar-burger text-blue-500" width={45} height={45} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width={56} height={56} rx={28} fill="currentColor" /><path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg></a>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80" />
        <nav className="relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto"><div className="flex flex-wrap justify-between h-full">
            <div className="w-full">
              <div className="flex items-center justify-between -m-2">
                <div className="w-auto p-2">
                  <a className="inline-block" href="#">
                    <img src="zanrly-assets/logos/zanrly-logo.svg" alt /></a>
                </div>
                <div className="w-auto p-2">
                  <a className="inline-block navbar-burger" href="#">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 18L18 6M6 6L18 18" stroke="#111827" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" /></svg></a>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center py-8 w-full">
              <ul>
                <li className="mb-9"><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#">Features</a></li>
                <li className="mb-9"><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#">Solutions</a></li>
                <li className="mb-9"><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#">Resources</a></li>
                <li><a className="inline-block text-sm font-bold text-gray-900 hover:text-gray-700" href="#">Pricing</a></li>
              </ul>
            </div>
            <div className="flex flex-col justify-end w-full pb-8">
              <div className="flex flex-wrap -m-2">
                <div className="w-full p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full" href="#">Log In</a></div>
                <div className="w-full p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="#">Get Started</a></div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div className="mb-8 p-8 bg-white rounded-3xl">
      <div className="flex flex-wrap lg:items-center -m-8">
        <div className="w-full md:w-1/2 p-8">
          <div className="md:max-w-lg mx-auto">
            <span className="inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest">MARKETING UGC</span>
            <h1 className="font-heading mb-4 text-5xl text-gray-900 font-black tracking-tight">
              <span>Générer de l'interaction et du contenu</span>
              <span className="text-transparent bg-clip-text bg-gradient-orange">simplement</span>
              <span>.</span>
            </h1>
            <p className="mb-6 text-xl font-bold">Utilisez votre fréquentation pour la transformer en levier de croissance et boostez votre visibilité.</p>
            <div className="flex flex-wrap -m-2">
              <div className="w-full md:w-auto p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="https://calendly.com/loan-talvat/recontrons-nous">Demander une présentation</a></div>
              <div className="w-full md:w-auto p-2"><a className="block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 rounded-full" href="https://app.feedwall.fr">Connexion</a></div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 p-8">
          <div className="max-w-max mx-auto md:mr-0 bg-white overflow-hidden rounded-3xl">
            <img className="mx-auto" src="https://images.unsplash.com/photo-1527618798212-3bc916c4e9c0?crop=entropy&cs=srgb&fm=jpg&ixid=M3wzMzIzMzB8MHwxfHNlYXJjaHwyMXx8c21hcnRwaG9uZXxlbnwwfHx8fDE3MjI4NzMxMjR8MA&ixlib=rb-4.0.3&q=85&w=1920" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

