import React from 'react';

export default function IndexSectionNumbersLightReverse6() {
    return (
        <React.Fragment>
            <>
                <section className="py-10 bg-gray-50 overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="py-16 px-8 bg-white border border-gray-100 rounded-3xl">
      <div className="max-w-7xl mx-auto">
        <div className="mb-12 max-w-lg mx-auto text-center">
          <h2 className="font-heading mb-6 text-4xl md:text-5xl text-gray-900 font-black tracking-tight">En quelques chiffres
          </h2>
          <p className="text-gray-500 font-bold">Découvrez leur&nbsp;performances, et imaginez les votres...</p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="w-full md:w-1/2 p-4">
            <div className="py-14 px-8 text-center h-full bg-gray-100 rounded-3xl">
              <div className="md:max-w-xs mx-auto">
                <h2 className="font-heading mb-6 text-5xl md:text-6xl lg:text-7xl text-gray-900 font-black tracking-tight"><span className="text-transparent bg-clip-text bg-gradient-green-dark">78%</span></h2>
                <h3 className="font-heading mb-3.5 text-xl text-gray-900 font-bold">Croissance de stories publiées
                </h3>
                <p className="text-gray-600 font-bold">Moyenne de croissance des stories publiés
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 p-4">
            <div className="py-14 px-8 text-center h-full bg-gray-100 rounded-3xl">
              <div className="md:max-w-xs mx-auto">
                <h2 className="font-heading mb-6 text-5xl md:text-6xl lg:text-7xl text-gray-900 font-black tracking-tight"><span className="text-transparent bg-clip-text bg-gradient-orange">489%</span></h2>
                <h3 className="font-heading mb-3.5 text-xl text-gray-900 font-bold">Retour sur investissement
                </h3>
                <p className="text-gray-600 font-bold">Retour sur investissement moyen
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

