import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionHeadersLightReverse1 from '../components/headers-light-reverse/IndexSectionHeadersLightReverse1';
import IndexSectionHowItWorksLightReverse2 from '../components/how-it-works-light-reverse/IndexSectionHowItWorksLightReverse2';
import IndexSectionApplicationsLightReverse3 from '../components/applications-light-reverse/IndexSectionApplicationsLightReverse3';
import IndexSectionPricingLightReverse4 from '../components/pricing-light-reverse/IndexSectionPricingLightReverse4';
import IndexSectionNumbersLightReverse6 from '../components/numbers-light-reverse/IndexSectionNumbersLightReverse6';
import IndexSectionTestimonialsLightReverse7 from '../components/testimonials-light-reverse/IndexSectionTestimonialsLightReverse7';
import IndexSectionFootersLightReverse5 from '../components/footers-light-reverse/IndexSectionFootersLightReverse5';

const meta = {
  title:
    'Feedwall\u0020\u002D\u0020L\u0027outil\u00203\u0020en\u0020un\u0020pour\u0020g\u00E9n\u00E9rer\u0020du\u0020contenu\u0020naturellement',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionHeadersLightReverse1 />
      <IndexSectionHowItWorksLightReverse2 />
      <IndexSectionApplicationsLightReverse3 />
      <IndexSectionPricingLightReverse4 />
      <IndexSectionNumbersLightReverse6 />
      <IndexSectionTestimonialsLightReverse7 />
      <IndexSectionFootersLightReverse5 />
    </React.Fragment>
  );
}

