import React from 'react';

export default function IndexSectionPricingLightReverse4() {
    return (
        <React.Fragment>
            <>
                <section className="py-16 bg-gray-100 overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="mb-12 max-w-2xl mx-auto text-center">
      <h2 className="font-heading text-4xl md:text-5xl text-gray-900 font-black tracking-tight">Tarifs</h2>
    </div>
    <div className="flex flex-wrap -m-4">
      <div className="w-full md:w-1/3 p-4">
        <div className="flex flex-col justify-between p-8 h-full bg-white rounded-3xl">
          <div className="flex-initial mb-6">
            <span className="inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest">Basic</span>
            <h2 className="font-heading text-4xl text-gray-900 font-black tracking-tight">49€/mois</h2>
            <p className="mb-8 text-sm text-gray-400 font-bold">Facturé annuellement
            </p>
            <ul>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">1&nbsp; compte
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">200 stories mensuellement
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">10 Go de stockage
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83334" stroke="#D1D5DB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-400 font-bold">Module diffusion
                </p>
              </li>
              <li className="flex items-center">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83334" stroke="#D1D5DB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-400 font-bold">Support premium
                </p>
              </li>
            </ul>
          </div>
          <div className="flex-initial">
            <div className="flex flex-wrap -m-2">
              <div className="w-full p-2"><a className="block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="https://app.feedwall.fr">S'inscrire</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-4">
        <div className="flex flex-col justify-between p-8 h-full bg-gray-100 border border-gray-200 rounded-3xl shadow-md">
          <div className="flex-initial mb-6">
            <span className="inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest">Standard</span>
            <h2 className="font-heading text-4xl text-gray-900 font-black tracking-tight">
              <span>129€</span><span className="text-xl text-gray-500 font-bold">/mois</span>
            </h2>
            <p className="mb-8 text-sm text-gray-400 font-bold">Facturé annuellement</p>
            <ul>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">5 comptes
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">2000 stories mensuellement
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">Stockage illimité
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83334" stroke="#D1D5DB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-400 font-bold">Module diffusion
                </p>
              </li>
              <li className="flex items-center">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83334" stroke="#D1D5DB" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-400 font-bold">Support premium
                </p>
              </li>
            </ul>
          </div>
          <div className="flex-initial">
            <div className="flex flex-wrap -m-2">
              <div className="w-full p-2"><a className="block w-full px-8 py-3.5 text-lg text-center text-gray-900 font-bold bg-white hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 rounded-full" href="https://app.feedwall.fr">S'inscrire</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-4">
        <div className="flex flex-col justify-between p-8 h-full bg-white rounded-3xl">
          <div className="flex-initial mb-6">
            <span className="inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest">ENTREPRISE
            </span>
            <h2 className="font-heading text-4xl text-gray-900 font-black tracking-tight">
              <span>359€</span><span className="text-xl text-gray-500 font-bold">/mois</span>
            </h2>
            <p className="mb-8 text-sm text-gray-400 font-bold">Facturé annuellement</p>
            <ul>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">Comptes illimité
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">50 000 stories mensuellement
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="text-gray-900 font-bold">Stockage illimité
                </p>
              </li>
              <li className="flex items-center mb-4">
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="font-bold">Module diffusion
                </p>
              </li>
              <li className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="mr-2.5" width={20} height={20} viewBox="0 0 20 20" fill="none">
                  <path d="M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333" stroke="#3B82F6" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p className="font-bold">Support premium
                </p>
              </li>
            </ul>
          </div>
          <div className="flex-initial">
            <div className="flex flex-wrap -m-2">
              <div className="w-full p-2"><a className="block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="https://app.feedwall.fr">S'inscrire</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

