import React from 'react';

export default function IndexSectionApplicationsLightReverse3() {
    return (
        <React.Fragment>
            <>
                <section className="py-10 bg-gray-50 overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="py-20 px-8 md:px-16 overflow-hidden rounded-3xl" style={{backgroundImage: 'url("zanrly-assets/images/applications/website.png")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      <div className="max-w-7xl mx-auto">
        <div className="md:max-w-lg">
          <h2 className="font-heading mb-6 text-4xl md:text-5xl text-gray-100 font-black tracking-tight">Lancez-vous
          </h2>
          <p className="mb-8 text-gray-200 font-bold">Il ne vous reste plus qu'un pas pour passer des promesses à la réalité
          </p>
          <div className="flex flex-wrap -m-2">
            <div className="w-full md:w-auto p-2"><a className="block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 rounded-full" href="https://calendly.com/loan-talvat/recontrons-nous">Prendre rendez-vous
              </a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

