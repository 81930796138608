import React from 'react';

export default function IndexSectionTestimonialsLightReverse7() {
    return (
        <React.Fragment>
            <>
                <section className="py-10 bg-gray-100 border border-gray-100 ooverflow-hidden">
  <div className="container mx-auto px-4">
    <div className="bg-white rounded-3xl">
      <div className="py-16 px-8 md:max-w-3xl mx-auto text-center">
        <img className="mb-6 mx-auto rounded-full" src="zanrly-assets/images/testimonials/avatar.png" alt />
        <p className="mb-10 text-2xl font-bold">"Nous utilisons Feedwall depuis plusieurs semaines maintenant et les interactions de nos clients sur Instagram ont explosé ! Nos clients sont contents, nous aussi, c'est superbe !"</p>
        <h3 className="font-heading mb-2 text-2xl text-gray-900 font-black">Jessica
        </h3>
        <p className="text-sm text-gray-500 font-bold">Agence événementielle</p>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

