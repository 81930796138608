import React from 'react';

export default function IndexSectionFootersLightReverse5() {
    return (
        <React.Fragment>
            <>
                <section className="py-10 bg-gray-50 overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="py-16 px-8 mb-8 bg-white rounded-3xl">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-wrap items-center justify-between -m-4">
          <div className="w-auto p-4"><a className="inline-block" href="#"><img src="https://static.shuffle.dev/uploads/files/03/03eba743df2df50e83283b462b4e53403afef802/feedwall.svg" alt className="max-w-44" /></a></div>
          <div className="w-auto p-4">
            <ul className="-m-6">
              <li className="inline-flex p-6"><a className="inline-block text-gray-500 hover:text-gray-600 font-bold" href="https://calendly.com/loan-talvat/recontrons-nous">Fonctionnalités</a></li>
              <li className="inline-flex p-6"><a className="inline-block text-gray-500 hover:text-gray-600 font-bold" href="#tarifs">Tarifs</a></li>
              <li className="inline-flex p-6"><a className="inline-block text-gray-500 hover:text-gray-600 font-bold" href="https://calendly.com/loan-talvat/recontrons-nous">Contact
                </a></li>
            </ul>
          </div>
          <div className="w-auto p-4">
            <div className="flex flex-wrap -m-4">
            </div>
          </div>
        </div>
      </div>
    </div>
    <p className="text-center font-bold"><span>🍪 Ce site n'utilise pas de cookies</span></p>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

